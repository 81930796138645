import { createSelector } from 'reselect';

import { DEFAULT_AUTO_COMPARE_LIMIT, MAX_AUTO_COMPARE_LIMIT } from '@neptune/compare-domain';
import { getCurrentProjectProjectKey } from '@neptune/current-project-business-logic';
import {
  Entity,
  getEntityShortId,
  getRunIdentificationPair,
  isRunIdentifierPair,
} from '@neptune/shared/entity-domain';
import { LeaderboardId } from '@neptune/shared/entity-leaderboard-domain';
import { getLeaderboardState } from '@neptune/shared/leaderboard-business-logic';
import { getCurrentRouteParams } from '@neptune/shared/routing-business-logic';

import { AppState } from 'state/types';

import { extractCachedCompareState } from './utils';

export const getCompareCurrentRouteParams = (state: AppState): string =>
  getCurrentRouteParams(state).compare;

const getAutoModeCompareIdsForCurrentProject = (state: AppState): string[] | undefined =>
  getCompareAutoModeForCurrentProject(state) ? getAutoModeCompareIds(state) : undefined;

const EMPTY_RUN_IDS: string[] = [];
export const getCompareIdsForCurrentProject: (state: AppState) => string[] = createSelector(
  getCompareAutoModeForCurrentProject,
  getCompareCurrentRouteParams,
  getCurrentProjectProjectKey,
  getAutoModeCompareIdsForCurrentProject,
  (
    autoMode: boolean,
    packedCompareIds: string | undefined,
    projectKey: string | undefined,
    autoModeIds: string[] | undefined,
  ) => {
    if (autoMode) {
      return autoModeIds || EMPTY_RUN_IDS;
    }

    if (projectKey != null && packedCompareIds != null) {
      return extractCachedCompareState(packedCompareIds, projectKey);
    }

    return EMPTY_RUN_IDS;
  },
);

export function getCompareAutoModeForCurrentProject(state: AppState): boolean {
  return (
    !!getCompareCurrentRouteParams(state) && getCompareCurrentRouteParams(state).startsWith('auto')
  );
}

export const getCompareAutoLimitForCurrentProject = createSelector(
  getCompareCurrentRouteParams,
  getCompareAutoModeForCurrentProject,
  (compareParam, autoMode) => {
    if (!autoMode) {
      return;
    }

    const regex = /auto-(\d+)/g;
    const match = regex.exec(compareParam);
    const limit = match ? parseInt(match[1]) : DEFAULT_AUTO_COMPARE_LIMIT;
    return Math.min(limit, MAX_AUTO_COMPARE_LIMIT);
  },
);

export const getAutoModeCompareKeys: (
  state: AppState,
  id: LeaderboardId.Reports | LeaderboardId.Runs,
  customCompareLimit?: number,
) => [string[], string[]] = createSelector(
  getLeaderboardState,
  getCompareAutoLimitForCurrentProject,
  (state: AppState, id: LeaderboardId.Reports | LeaderboardId.Runs, customCompareLimit?: number) =>
    customCompareLimit,
  (leaderboard, urlBasedAutoCompareLimit, customAutoCompareLimit) => {
    const runIdentificationPairs = leaderboard.entries
      .slice(0, customAutoCompareLimit || urlBasedAutoCompareLimit || DEFAULT_AUTO_COMPARE_LIMIT)
      .map(getRunIdentificationPair)
      .filter(isRunIdentifierPair);

    const runIds = [];
    const experimentNames = [];

    for (const pair of runIdentificationPairs) {
      if (pair.experimentName) {
        experimentNames.unshift(pair.experimentName);
      } else {
        runIds.unshift(pair.runId);
      }
    }

    return [experimentNames, runIds];
  },
);

const ID_SEPARATOR = '|';

// We need to keep the array stable as long as the ids aren't actually changing,
// so we use string as an intermediate value that keeps === equality as we need.
const getAutoModeCompareIdsAsString = createSelector(
  (state) => getLeaderboardState<Entity>(state, 'runs'),
  getCompareAutoLimitForCurrentProject,

  (leaderboard, autoCompareLimit): string =>
    leaderboard.entries
      .slice(0, autoCompareLimit)
      .map((entry: Entity) => getEntityShortId(entry))
      .sort()
      .join(ID_SEPARATOR),
);

const getAutoModeCompareIds = createSelector(
  getAutoModeCompareIdsAsString,
  (stringValue): string[] => stringValue.split(ID_SEPARATOR),
);
