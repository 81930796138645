import { DEFAULT_COLOR } from './color-palette';

const RGBA_RE =
  /^rgba?\((\d{1,3})[, ]\s*(\d{1,3})[, ]\s*(\d{1,3})\s*(?:[,/]\s*([01](?:\.\d+)?))?\)$/;
const HEX_RE = /^#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})?$/i;

const DEFAULT_COLOR_PARSED = parseHEX(DEFAULT_COLOR) as {
  r: number;
  g: number;
  b: number;
};

export function parseColor(rgbStr: string) {
  return parseRGBA(rgbStr) ?? parseHEX(rgbStr) ?? DEFAULT_COLOR_PARSED;
}

function parseRGBA(rgbStr: string) {
  const bits = RGBA_RE.exec(rgbStr);

  if (!bits) {
    return null;
  }

  const r = parseInt(bits[1], 10),
    g = parseInt(bits[2], 10),
    b = parseInt(bits[3], 10);

  if (bits[4]) {
    return { r, g, b, a: parseFloat(bits[4]) };
  }

  return { r, g, b };
}

function parseHEX(rgbStr: string) {
  const bits = HEX_RE.exec(rgbStr);

  if (!bits) {
    return null;
  }

  const r = parseInt(bits[1], 16),
    g = parseInt(bits[2], 16),
    b = parseInt(bits[3], 16);

  if (bits[4]) {
    return { r, g, b, a: parseInt(bits[4], 16) / 255 };
  }

  return { r, g, b };
}
