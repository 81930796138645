import React from 'react';
import { noop } from 'lodash';

import { DEFAULT_COLOR } from '@neptune/colors-domain';
import { ColorsContext } from '@neptune/compare-domain';

export function createColorsContext(name: string) {
  const ColorsContext = React.createContext<ColorsContext>({
    key: '',
    colors: new Map(),
    setColor: noop,
    assignColors: noop,
    initializeColors: noop,
    makeColorKey: () => '',
    getColor: () => DEFAULT_COLOR,
    getRGBColors: () => new Map(),
  });

  function useColorsContext() {
    return React.useContext(ColorsContext);
  }

  ColorsContext.displayName = name;

  return { ColorsContext, useColorsContext };
}
