import React from 'react';
import { noop } from 'lodash';

import { COLOR_PALETTE, DEFAULT_COLOR, HEXColor } from '@neptune/colors-domain';
import { useRunsColorsContext } from '@neptune/compare-business-logic';

import { Attribute, getRunIdentificationKeyFromAttributes } from 'domain/experiment/attribute';

type ItemColor = HEXColor | undefined;
type Item = { attributes: Attribute[]; organizationName: string; projectName: string };

/**
 *
 * Returns a list of functions that can be used to get the color of an entity.
 * When used with *ColorPaletteContext* functions with lower index have higher priority
 *
 */
export function useRunDetailsColorsSources(): [
  (((item: Item) => ItemColor | undefined) | undefined)[],
  { getColorForGroup: (tag: string) => ItemColor },
] {
  const { getColor, makeColorKey } = useRunsColorsContext();

  return React.useMemo(
    () => [
      [
        (item: Item) => {
          const { organizationName, projectName } = item;
          const runIdentificationKey = getRunIdentificationKeyFromAttributes(item.attributes);

          if (!organizationName || !projectName || !runIdentificationKey) {
            return getColor() as ItemColor;
          }

          const color = getColor(
            makeColorKey(organizationName, projectName, runIdentificationKey),
          ) as ItemColor;

          return color === DEFAULT_COLOR ? COLOR_PALETTE[9][0] : color;
        },
      ],
      { getColorForGroup: noop as (tag: string) => ItemColor },
    ],
    [getColor, makeColorKey],
  );
}
