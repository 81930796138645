import React from 'react';

import { isMacOperatingSystem } from '@neptune/shared/common-util';
import { SearchMode } from '@neptune/shared/search-domain';
import { bemBlock, Button, Icon, KeyShortcut, Text } from '@neptune/shared/venus-ui';

import { RegExpInstructionsTooltip } from './RegExpInstructionsTooltip';

import './SearchModeSwitcher.less';

type SearchModeSwitcherProps = {
  onClick: () => void;
  value: SearchMode;
};

const commandKeyUnicode = '\u2318';

const block = bemBlock('search-mode-switcher');

export const SearchModeSwitcher: React.FC<SearchModeSwitcherProps> = ({ onClick, value }) => {
  return (
    <RegExpInstructionsTooltip
      placement="top-center"
      shortcutSlot={
        <KeyShortcut theme="dark">
          <Text size="xs" fontWeight="normal">
            {isMacOperatingSystem ? commandKeyUnicode : 'CTRL'}+/
          </Text>
        </KeyShortcut>
      }
    >
      <Button
        className={block({
          modifiers: {
            active: value === SearchMode.REGEX,
          },
        })}
        variant="transparent"
        onClick={onClick}
        data-role="search-mode-switcher-button"
        square
      >
        <Icon glyph="regexp" />
      </Button>
    </RegExpInstructionsTooltip>
  );
};
