import React from 'react';

import { useLocalModal } from '@neptune/shared/common-util';
import { RegExpInstructionsModal } from '@neptune/shared/regexp-instructions-modal-ui';
import { Layout, Link, SimpleTooltip, Text } from '@neptune/shared/venus-ui';

export const RegExpInstructionsTooltip: React.FC<{
  placement?: React.ComponentProps<typeof SimpleTooltip>['placement'];
  additionalContent?: React.ReactNode;
  shortcutSlot?: React.ReactNode;
}> = ({ children, placement, additionalContent, shortcutSlot }) => {
  const { isOpen, open, close } = useLocalModal();

  return (
    <>
      <RegExpInstructionsModal onClose={close} isOpen={isOpen} />
      <SimpleTooltip
        placement={placement}
        children={children}
        content={
          <Layout.Row spacedChildren="sm" alignItems="baseline">
            {additionalContent && <Layout.Column span="greedy">{additionalContent}</Layout.Column>}
            <Layout.Column span="greedy">
              <Text>
                Use regular expression. Refer to{' '}
                <Link
                  data-role="regexp-explanation-button"
                  onClick={(event) => {
                    event.stopPropagation();
                    open();
                  }}
                >
                  helpers
                </Link>{' '}
                for assistance.
              </Text>
            </Layout.Column>
            {shortcutSlot && <Layout.Column span="auto" children={shortcutSlot} />}
          </Layout.Row>
        }
      />
    </>
  );
};
