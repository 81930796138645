import { backendClient } from '@neptune/shared/core-apis-backend-domain';
import { projectWithRoleFromApiToDomain } from '@neptune/shared/core-project-domain';

export function updateLastViewedProject(projectId: string) {
  return backendClient.updateLastViewed({ projectId });
}

export async function getProjectsDetails(projectIdentifiers: string[]) {
  try {
    const projectList = await backendClient.listRolesForUser({ projectIdentifiers });
    return projectList.entries.map(projectWithRoleFromApiToDomain);
  } catch (error) {
    throw error;
  }
}
