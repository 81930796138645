import React from 'react';
import { RE2JS } from 're2js';

import { isValidRegExp } from '@neptune/shared/common-util';

import { SearchQueryTerm, SearchQueryTermType } from './types';

export const isValidOperator = (operator: string) =>
  ['AND', 'OR', 'AND NOT'].includes(operator.trim().toUpperCase());

export const resolveInputModeFromTerms = (terms: SearchQueryTerm[]) => {
  const lastTerm = terms[terms.length - 1];

  if (!lastTerm || lastTerm.type === SearchQueryTermType.OPERATOR) {
    return SearchQueryTermType.CRITERION;
  }

  return SearchQueryTermType.OPERATOR;
};

export function isValidSearchQuery(terms: SearchQueryTerm[]) {
  if (terms.length === 0) {
    return true;
  }

  if (terms[0].type !== SearchQueryTermType.CRITERION) {
    return false;
  }

  return terms.every((term, index) => {
    const expectedType =
      index % 2 === 0 ? SearchQueryTermType.CRITERION : SearchQueryTermType.OPERATOR;

    if (term.type !== expectedType) {
      return false;
    }

    if (term.type === SearchQueryTermType.OPERATOR) {
      return isValidOperator(term.value);
    }

    if (term.type === SearchQueryTermType.CRITERION) {
      return isValidRegExp(term.value);
    }

    return false;
  });
}

export const formatOperator = (a: string) => a.toUpperCase().trim();

export const escapeTextToEquivalentRegexp = (text: string) => RE2JS.quote(text);

export const preventSyntheticDefaultEvent: React.EventHandler<React.SyntheticEvent> = (e) =>
  e.preventDefault();

export const stopSyntheticPropagation: React.EventHandler<React.SyntheticEvent> = (e) =>
  e.stopPropagation();
