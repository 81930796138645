import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  searchModelConverter,
  SearchQueryTerm,
  SearchQueryTermType,
} from '@neptune/compound-search-domain';
import {
  type ComplexSearchFilter,
  CompoundSearchCriterion,
  CompoundSearchFilter,
  isEmptyFilter,
  loadFilter,
  persistFilter,
  TextFilter,
} from '@neptune/recent-searches-domain';
import { regexpEscaper, useAsyncValue } from '@neptune/shared/common-util';
import {
  createCurrentRouteParamSelector,
  getCurrentRouteName,
  navigateTo,
} from '@neptune/shared/routing-business-logic';
import { AttributeNameFilter, SearchMode } from '@neptune/shared/search-domain';

const modeSelector = createCurrentRouteParamSelector('searchMode');

export function useSearchFilters(namespace: string): {
  mode: SearchMode;
  setMode: (newMode: SearchMode, queryToPersist?: string) => void;
  isLoading: boolean;
  searchQueryTerms: SearchQueryTerm[];
  updateTermsFilter: (searchQueryTerms: SearchQueryTerm[]) => void;
  updateSingleTermFilter: (value: string) => void;
  toggleMode: (queryToPersist?: string) => void;
  attributeNameFilter: AttributeNameFilter | undefined;
} {
  const dispatch = useDispatch();
  const compoundNamespace = `${namespace}-compound`;
  const routeName = useSelector(getCurrentRouteName);
  const textFilter: string | undefined = useSelector(createCurrentRouteParamSelector(namespace));
  const compoundSearchFilterKey: string | undefined = useSelector(
    createCurrentRouteParamSelector(compoundNamespace),
  );

  const resolver = React.useCallback(
    async () => loadFilter(compoundSearchFilterKey),
    [compoundSearchFilterKey],
  );
  const { value: compoundSearchFilter, loading } = useAsyncValue({ resolver });

  const defaultMode = compoundSearchFilterKey != null ? SearchMode.REGEX : SearchMode.SUBSTRING;

  const mode: SearchMode = useSelector(modeSelector) ?? defaultMode;

  const changeFilter = React.useCallback(
    async (newFilter?: ComplexSearchFilter): Promise<void> => {
      let newState = {};

      if (!newFilter) {
        newState = {
          [namespace]: undefined,
          [compoundNamespace]: undefined,
        };
      }

      if (newFilter?.searchType === SearchMode.REGEX) {
        const key = !isEmptyFilter(newFilter) ? await persistFilter(newFilter.value) : undefined;
        newState = {
          [namespace]: undefined,
          [compoundNamespace]: key,
        };
      }

      if (newFilter?.searchType === SearchMode.SUBSTRING) {
        newState = {
          [namespace]: newFilter.value,
          [compoundNamespace]: undefined,
        };
      }

      dispatch(navigateTo(routeName, newState, { extendParams: true, replace: true }));
    },
    [dispatch, routeName, namespace, compoundNamespace],
  );

  const updateSingleTermFilter = React.useCallback(
    (value: string) => {
      if (!value) {
        changeFilter(undefined);
        return;
      }

      const newFilter: TextFilter = {
        searchType: SearchMode.SUBSTRING,
        value,
      };

      changeFilter(newFilter);
    },
    [changeFilter],
  );

  const updateTermsFilter = React.useCallback(
    (newQueryTerms: SearchQueryTerm[]) => {
      if (newQueryTerms.length === 0) {
        changeFilter(undefined);
        return;
      }

      const adaptedValue: CompoundSearchFilter = {
        searchType: SearchMode.REGEX,
        value: newQueryTerms.map(({ type, value }) => ({
          type: type === SearchQueryTermType.CRITERION ? 'regex' : 'operator',
          value,
        })),
      };

      changeFilter(adaptedValue);
    },
    [changeFilter],
  );

  const switchToSubStringMode = React.useCallback(() => {
    dispatch(
      navigateTo(
        routeName,
        {
          searchMode: SearchMode.SUBSTRING,
          [namespace]: undefined,
          [compoundNamespace]: undefined,
        },
        { extendParams: true, replace: true },
      ),
    );
  }, [dispatch, routeName, namespace, compoundNamespace]);

  const switchToRegexMode = React.useCallback(
    (queryToPersist?: string) => {
      dispatch(
        navigateTo(
          routeName,
          {
            searchMode: SearchMode.REGEX,
            [namespace]: undefined,
            [compoundNamespace]: undefined,
          },
          { extendParams: true, replace: true },
        ),
      );

      if (queryToPersist && queryToPersist.length > 0) {
        const escapedQuery = regexpEscaper.escape(queryToPersist);
        updateTermsFilter(stringToSearchQueryTerms(escapedQuery));
      }
    },
    [dispatch, routeName, namespace, compoundNamespace, updateTermsFilter],
  );

  const setMode = React.useCallback(
    (newMode: SearchMode, queryToPersist?: string) => {
      if (newMode === SearchMode.SUBSTRING) {
        switchToSubStringMode();
        return;
      }

      switchToRegexMode(queryToPersist);
    },
    [switchToSubStringMode, switchToRegexMode],
  );

  const searchQueryTerms = React.useMemo(() => {
    if (mode === SearchMode.REGEX) {
      return compoundSearchFilter ? fromCriterionsToTerms(compoundSearchFilter) : [];
    }

    return textFilter?.length && textFilter.length > 0 ? stringToSearchQueryTerms(textFilter) : [];
  }, [mode, compoundSearchFilter, textFilter]);

  const toggleMode = React.useCallback(
    (queryToPersist?: string) => {
      if (mode === SearchMode.REGEX) {
        setMode(SearchMode.SUBSTRING, queryToPersist);
        return;
      }

      setMode(SearchMode.REGEX, queryToPersist);
    },
    [mode, setMode],
  );

  /**
   * Placing this here for convenience, however, a refactor would be desirable since AttributeNameFilter should not be a
   * "domain" concern, but a "infrastructure" concern (it is used mainly by the API, and this DTO should not leak).
   */
  const attributeNameFilter: AttributeNameFilter | undefined = React.useMemo(() => {
    if (isEmpty(searchQueryTerms)) {
      return undefined;
    }

    if (mode === SearchMode.SUBSTRING) {
      return searchModelConverter.fromSearchQueryToSingleAttributeNameFilter(searchQueryTerms);
    }

    return searchModelConverter.fromSearchQueryToAttributeNameFilter(searchQueryTerms);
  }, [searchQueryTerms, mode]);

  return {
    mode,
    setMode,
    isLoading: loading,
    searchQueryTerms,
    updateTermsFilter,
    updateSingleTermFilter,
    toggleMode,
    attributeNameFilter,
  };
}

function stringToSearchQueryTerms(value: string): SearchQueryTerm[] {
  return [
    {
      type: SearchQueryTermType.CRITERION,
      value,
    },
  ];
}

// TODO: move all of fromCriterionsToTerms function to single place
function fromCriterionsToTerms(criterions: CompoundSearchCriterion[]): SearchQueryTerm[] {
  return criterions.map(({ type, value }) => ({
    type: type === 'regex' ? SearchQueryTermType.CRITERION : SearchQueryTermType.OPERATOR,
    value,
  }));
}
