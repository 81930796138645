import React from 'react';

import { DEFAULT_COLOR, HEXColor } from './color-palette';

type ColorPaletteContext = {
  getColor: <T>(key?: T) => T extends string ? HEXColor | undefined : typeof DEFAULT_COLOR;
  getGroupColor: <T>(key?: T) => T extends string ? HEXColor : typeof DEFAULT_COLOR;
};

export const ColorPaletteContext = React.createContext<ColorPaletteContext>({
  getColor: () => DEFAULT_COLOR,
  getGroupColor: () => DEFAULT_COLOR,
});
