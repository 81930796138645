export const PROJECT_NAME_MIN_LENGTH = 3;
export const PROJECT_NAME_MAX_LENGTH = 128;
const PROJECT_CHARACTERS_REGEX = '[^/\\\\#?%:]';

export const PROJECT_NAME_REGEX = new RegExp(
  `^${PROJECT_CHARACTERS_REGEX}{${PROJECT_NAME_MIN_LENGTH},${PROJECT_NAME_MAX_LENGTH}}$`,
);

/**
 * characters like whitespace are encoded by UTF-8 code units,
 * with each octet encoded in the format %XX in URLs, so we need to exclude % from the regex.
 */
export const PROJECT_URL_PATH_SEGMENT = `:projectName<${PROJECT_CHARACTERS_REGEX.replace('%', '')}+>`;
