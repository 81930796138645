import React from 'react';

import { usePolling } from '@neptune/shared/common-business-logic';
import { useMemoizedValue } from '@neptune/shared/common-util';
import { getRunIdentificationPair, isRunIdentifierPair } from '@neptune/shared/entity-domain';
import { TableViewParams } from '@neptune/shared/leaderboard-domain';

import { resolveAutoCompareEntries } from './resolve-auto-compare-entries';

type LimitAndSearchParams = {
  projectIdentifier: string;
  tableParams?: TableViewParams;
  autoComparePool?: number;
};

export function useResolveAutoCompare(props: LimitAndSearchParams) {
  const [resolved, setResolved] = React.useState<boolean>(false);
  const memoized = useMemoizedValue(props);

  const start = React.useCallback(() => setResolved(false), [setResolved]);
  const end = React.useCallback(() => setResolved(true), [setResolved]);

  const { result } = usePolling(memoized, {
    resolver,
    pollingInterval: 30_000,
    onFetchStarted: start,
    onFetchFailed: end,
    onFetchSucceed: end,
  });

  return {
    runIdentificationPairs: result,
    resolved,
  };
}

const resolver = async ({
  autoComparePool,
  projectIdentifier,
  tableParams,
}: LimitAndSearchParams) => {
  if (autoComparePool === undefined || !tableParams) {
    return;
  }

  const entries = await resolveAutoCompareEntries(projectIdentifier, tableParams, autoComparePool);

  const runIdentificationPairs = entries
    .map(getRunIdentificationPair)
    .filter(isRunIdentifierPair)
    .slice(0, autoComparePool);

  const runIds = [];
  const experimentNames = [];

  for (const pair of runIdentificationPairs) {
    if (pair.experimentName) {
      experimentNames.unshift(pair.experimentName);
    } else {
      runIds.unshift(pair.runId);
    }
  }

  return [experimentNames, runIds];
};
