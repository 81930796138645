import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  COMPARE_STORED_DATA_VERSION,
  CompareContent,
  loadCompareStateFromStore,
  persistCompareStateToStore,
} from '@neptune/compare-domain';
import { getCurrentProjectIdentifier } from '@neptune/current-project-business-logic';
import { TableViewParams } from '@neptune/shared/leaderboard-domain';
import {
  getCurrentRouteName,
  getCurrentRouteParams,
  navigateTo,
} from '@neptune/shared/routing-business-logic';

import { getOrganizationNameFromRouteParams } from 'state/selectors-global';
import { AppState } from 'state/types';
import { setAutoModeOn, setShowOnlySelectedRuns } from 'state/ui/compare/actions';
import {
  getCompareAutoLimitForCurrentProject,
  getCompareCurrentRouteParams,
  getCompareIdsForCurrentProject,
} from 'state/ui/compare/selectors';
import { hashCompareData } from 'state/ui/compare/utils';

import { useResolveAutoCompare } from './use-resolve-auto-compare';

const EMPTY_COMPARE_KEYS = [[], []];

export function useCompareKeysStore({ tableParams }: { tableParams?: TableViewParams }) {
  const dispatch = useDispatch();
  const autoCompareLimit = useSelector(getCompareAutoLimitForCurrentProject);
  const queryClient = useQueryClient();
  const workspaceName = useSelector(getOrganizationNameFromRouteParams);
  const projectIdentifier = useSelector(getCurrentProjectIdentifier);
  const routeName = useSelector(getCurrentRouteName);
  const currentRouteParams = useSelector(getCurrentRouteParams);
  const showOnlySelectedRuns = Boolean(currentRouteParams?.showOnlySelectedRuns);

  const decompressedRunIds = useSelector((state: AppState) =>
    getCompareIdsForCurrentProject(state),
  );
  const [compareKeys, setCompareKeys] = React.useState<{
    runIds: string[];
    experimentNames: string[];
  }>({ runIds: [], experimentNames: [] });
  const { runIdentificationPairs, resolved: autoCompareResolved } = useResolveAutoCompare({
    projectIdentifier,
    autoComparePool: autoCompareLimit,
    tableParams,
  });

  const [autoCompareExperimentNames, autoCompareIds] = runIdentificationPairs ?? EMPTY_COMPARE_KEYS;

  const currentCompareParam = useSelector(getCompareCurrentRouteParams);

  const updateURLState = React.useCallback(
    (compare: string | undefined) => {
      dispatch(navigateTo(routeName, { ...currentRouteParams, compare }, { replace: true }));
    },
    [currentRouteParams, dispatch, routeName],
  );

  const queryKey = ['retrieve-compare-key-value-data', workspaceName, projectIdentifier];

  const { mutate, isLoading: isMutating } = useMutation({
    mutationFn: persistCompareStateToStore,
    onMutate: async (content: CompareContent) => {
      updateURLState(await hashCompareData(content));
      await queryClient.cancelQueries({ queryKey });
      const existing = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, content);

      return { existing };
    },
    onError: async (_, __, context) => {
      if (context?.existing) {
        queryClient.setQueryData(queryKey, context.existing);
        updateURLState(await hashCompareData(context.existing as CompareContent));
      }
    },
  });

  const {
    data: retrievedData,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey,
    queryFn: () => loadCompareStateFromStore(currentCompareParam),
    enabled: !autoCompareLimit && currentCompareParam !== undefined && !isMutating,
  });

  React.useEffect(() => {
    if (retrievedData) {
      setCompareKeys(retrievedData.compare);
    }

    if (!retrievedData && isFetched) {
      // handle backward compatibility
      setCompareKeys({
        experimentNames: [],
        runIds: decompressedRunIds,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retrievedData, isLoading]);

  const saveCompareState = React.useCallback(
    async (runIds: string[], experimentNames: string[]) => {
      setCompareKeys({ runIds, experimentNames });

      if (runIds.length === 0 && experimentNames.length === 0) {
        updateURLState(undefined);
      } else {
        const content: CompareContent = {
          version: COMPARE_STORED_DATA_VERSION,
          workspaceName,
          projectIdentifier,
          compare: {
            runIds,
            experimentNames,
          },
        };

        mutate(content);
      }
    },
    [updateURLState, workspaceName, projectIdentifier, mutate],
  );

  const onAutoModeChange = React.useCallback(
    (autoCompareLimit?: number) => {
      if (autoCompareLimit === undefined) {
        saveCompareState(autoCompareIds, autoCompareExperimentNames);
      } else {
        dispatch(setAutoModeOn(autoCompareLimit));
      }
    },
    [autoCompareExperimentNames, autoCompareIds, dispatch, saveCompareState],
  );

  const showOnlySelectedRunsChange = React.useCallback(
    (showOnlySelectedRuns: boolean) => {
      dispatch(setShowOnlySelectedRuns(showOnlySelectedRuns));
    },
    [dispatch],
  );

  if (autoCompareLimit) {
    return {
      compareKeys: { runIds: autoCompareIds, experimentNames: autoCompareExperimentNames },
      autoCompareLimit,
      autoCompareResolved,
      onAutoModeChange,
      showOnlySelectedRuns,
      showOnlySelectedRunsChange,
      saveCompareState,
    };
  }

  return {
    compareKeys,
    autoCompareLimit,
    autoCompareResolved,
    onAutoModeChange,
    saveCompareState,
    showOnlySelectedRuns,
    showOnlySelectedRunsChange,
  };
}
