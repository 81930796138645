import {
  GroupByModelConverter,
  LeaderboardEntry,
  searchLeaderboard,
  SortOptionsModelConverter,
  TableViewParams,
} from '@neptune/shared/leaderboard-domain';

import { knownAttributesForExperimentIdentification } from 'domain/experiment/attribute';

export async function resolveAutoCompareEntries(
  projectIdentifier: string,
  tableParams: TableViewParams,
  autoComparePool: number,
): Promise<LeaderboardEntry[]> {
  const leaderboardResult = await searchLeaderboard({
    attributesToFetch: [...knownAttributesForExperimentIdentification],
    projectIdentifier,
    type: ['run'],
    experimentsOnly: tableParams.experimentsOnly,
    grouping: tableParams.groupOptions?.groupBy?.length
      ? {
          groupBy: GroupByModelConverter.fromGroupOptionsToDomain(tableParams.groupOptions),
          openedGroups: [],
        }
      : undefined,
    pagination: {
      limit: autoComparePool,
      offset: 0,
    },
    query: tableParams.query,
    sorting: SortOptionsModelConverter.fromLegacyToDomain(tableParams.sortOptions),
  });

  return leaderboardResult.entries;
}
