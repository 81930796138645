export type HEXColor = `#${string}`;
export type LABColor = [number, number, number];
export type ColorTuple = [HEXColor, LABColor];

export const DEFAULT_COLOR = '#8D9195' as const;

export const COLOR_PALETTE: ColorTuple[] = [
  ['#0346ff', [0.5098, -0.0299, -0.273]],
  ['#63d1f9', [0.8115, -0.0814, -0.081]],
  ['#fe7f21', [0.7299, 0.1154, 0.1395]],
  ['#089c08', [0.601, -0.1601, 0.1226]],
  ['#98df8a', [0.8361, -0.1041, 0.0861]],
  ['#d62728', [0.567, 0.1864, 0.0946]],
  ['#fea19d', [0.8013, 0.1029, 0.0426]],
  ['#9467bd', [0.5958, 0.0808, -0.1076]],
  ['#7b3f00', [0.4349, 0.0573, 0.0882]],
  ['#9b2f90', [0.503, 0.1595, -0.0838]],
  ['#f67ce8', [0.7607, 0.1723, -0.0925]],
  ['#000000', [0.0, 0.0, 0.0]],
  ['#838803', [0.6023, -0.0505, 0.1229]],
  ['#ffd300', [0.8789, -0.0113, 0.1797]],
  ['#0cb9a5', [0.7054, -0.1254, -0.0031]],
  ['#f00eca', [0.6516, 0.2608, -0.1071]],
  ['#a88e71', [0.6629, 0.0178, 0.0483]],
] as const;

export const COLOR_CLUSTERS: Set<ColorTuple>[] = [
  new Set([COLOR_PALETTE[9], COLOR_PALETTE[7]]),
  new Set([COLOR_PALETTE[15], COLOR_PALETTE[10]]),
  new Set([COLOR_PALETTE[0], COLOR_PALETTE[1]]),
  new Set([COLOR_PALETTE[5], COLOR_PALETTE[6]]),
  new Set([COLOR_PALETTE[3], COLOR_PALETTE[4], COLOR_PALETTE[12]]),
  new Set([COLOR_PALETTE[14]]),
  new Set([COLOR_PALETTE[8], COLOR_PALETTE[16]]),
  new Set([COLOR_PALETTE[2]]),
  new Set([COLOR_PALETTE[13]]),
  new Set([COLOR_PALETTE[11]]),
] as const;

export const COLOR_MAP = COLOR_PALETTE.reduce<Record<HEXColor, ColorTuple>>(
  (acc, color) => ((acc[color[0]] = color), acc),
  {},
);

export const COLOR_PICKER_COLORS = COLOR_PALETTE.slice(0, 16).map(([hEXColor]) => hEXColor);

/**
 * Colors carefully selected from the above palette. Do not change without talking to UX team.
 */
export const COLOR_PICKER_PRESET_COLORS = [
  '#9b2f90',
  '#9467bd',
  '#f00eca',
  '#f67ce8',
  '#0346ff',
  '#63d1f9',
  '#d62728',
  '#fea19d',
  '#089c08',
  '#98df8a',
  '#838803',
  '#0cb9a5',
  '#7b3f00',
  '#a88e71',
  '#fe7f21',
  '#ffd300',
];
