import React from 'react';
import { noop } from 'lodash';

type PresetColorsContext = {
  presetColors: string[];
  addPresetColor: (color: string) => void;
  initializePresetColors: (presetColors: string[]) => void;
};

export const PresetColorsContext = React.createContext<PresetColorsContext>({
  presetColors: [],
  addPresetColor: noop,
  initializePresetColors: noop,
});
