import React from 'react';

import { reconcileValue } from '@neptune/shared/common-util';
import { useEntityIdentificationEntries } from '@neptune/shared/entity-business-logic';
import { getRunIdentificationPair, isEmptyShortIdsMap } from '@neptune/shared/entity-domain';

type ExperimentNamesToRunId = Map<string, string>;
type ExperimentNamesToRunIdByProject = Map<string, ExperimentNamesToRunId>;

const EMPTY_MAP = new Map<any, never>();

export function useExperimentIdentificationMapSingleProject(
  projectIdentifier: string,
  experimentNames: string[],
  lastNotificationTimestamp?: number,
) {
  const experimentNamesByProject = React.useMemo(
    () => new Map([[projectIdentifier, experimentNames]]),
    [projectIdentifier, experimentNames],
  );

  const { namesToShortIdsByProject, namesToShortIdsReady, invalidateQuery } =
    useExperimentIdentificationMap(experimentNamesByProject);

  const namesToShortIds = React.useMemo(
    () => namesToShortIdsByProject.get(projectIdentifier) ?? new Map(),
    [projectIdentifier, namesToShortIdsByProject],
  );

  React.useEffect(() => {
    invalidateQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastNotificationTimestamp]);

  return { namesToShortIds, namesToShortIdsReady, invalidateQuery };
}

export function useExperimentIdentificationMap(experimentNamesByProject: Map<string, string[]>): {
  invalidateQuery: (projectId?: string) => void;
  namesToShortIdsByProject: ExperimentNamesToRunIdByProject;
  namesToShortIdsReady: boolean;
} {
  const [namesToShortIdsByProject, setNamesToShortIdsByProject] =
    React.useState<ExperimentNamesToRunIdByProject>(EMPTY_MAP);

  const { result, invalidateQuery } = useEntityIdentificationEntries(experimentNamesByProject);

  React.useEffect(() => {
    if (!result.data) {
      return;
    }

    const namesToRunIds = new Map<string, ExperimentNamesToRunId>();

    for (const [projectIdentifier, entries] of result.data) {
      for (const entry of entries) {
        const runIdentification = getRunIdentificationPair(entry);

        if (!runIdentification) {
          continue;
        }

        if (runIdentification.experimentName && runIdentification.runId) {
          let projectMap = namesToRunIds.get(projectIdentifier);

          if (!projectMap) {
            projectMap = new Map();
            namesToRunIds.set(projectIdentifier, projectMap);
          }

          projectMap.set(runIdentification.experimentName, runIdentification.runId);
        }
      }
    }

    setNamesToShortIdsByProject((prev) => reconcileValue(namesToRunIds, prev));
  }, [result.data]);

  return {
    namesToShortIdsByProject,
    invalidateQuery,
    namesToShortIdsReady: isEmptyShortIdsMap(experimentNamesByProject) || !result.isLoading,
  };
}
